import React, { useEffect, Suspense } from "react";
import { useLoader } from "./util/LoaderContext";
import { getAuthToken } from "./util/auth";
// eslint-disable-next-line no-unused-vars

import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
// const LazyLogin = React.lazy(() => import("./components/pages/login"));
// import config from "config";
import {GlobalLoadingFallback} from "./components/GlobalLoadingFallback";
const Login = React.lazy(() => import ("./components/pages/login"));
const Addblog = React.lazy(() => import("./components/pages/Blog/Addblog"));
const Editblog = React.lazy(() => import("./components/pages/Blog/Editblog"));
const BlogView = React.lazy(() => import("./components/pages/Blog/BlogView"));
const Blogdetails = React.lazy(() => import("./components/pages/Blog/Blogdetails"));
//For Settings...
// import Settings from "./components/settings/Settings";
const Localization = React.lazy(() => import("./components/settings/Localization"));
const Paymentsetting = React.lazy(() => import("./components/settings/Paymentsetting"));
const Settingsemail = React.lazy(() => import("./components/settings/Settingsemail"));
const Settingssocialmedia = React.lazy(() => import("./components/settings/Settingssocialmedia"));
const Settingssociallinks = React.lazy(() => import("./components/settings/Settingssociallinks"));
const Settingsseo = React.lazy(() => import("./components/settings/Settingsseo"));
const SettingsThem = React.lazy(() => import("./components/settings/SettingsThem"));
const SettingsChangePassword = React.lazy(() => import("./components/settings/SettingsChangePassword"));
const SettingsOthers = React.lazy(() => import("./components/settings/SettingsOthers"));
//Assest
const Assests = React.lazy(() => import("./components/assests/Assests"));
const AddAsset = React.lazy(() => import("./components/assests/AddAsset"));
//Doctor
const DoctorList = React.lazy(() => import("./components/doctor/DoctorList"));
const AddDoctor = React.lazy(() => import("./components/doctor/AddDoctor"));
const EditDoctor = React.lazy(() => import("./components/doctor/EditDoctor"));
const DoctorProfile = React.lazy(() => import("./components/doctor/DoctorProfile"));

//Receptionist
const ReceptionistList = React.lazy(() => import("./components/receptionist/ReceptionistList"));
const AddReceptionist = React.lazy(() => import("./components/receptionist/AddReceptionist"));
const EditReceptionist = React.lazy(() => import("./components/receptionist/EditReceptionist"));

//Nurse
const NurseList = React.lazy(() => import("./components/nurse/NurseList"));
const AddNurse = React.lazy(() => import("./components/nurse/AddNurse"));
const EditNurse = React.lazy(() => import("./components/nurse/EditNurse"));

//Patients...
const PatientBilling = React.lazy(() => import("./components/patients/PatientBilling"));
const PatientsList = React.lazy(() => import("./components/patients/PatientsList"));
const WaitingList = React.lazy(() => import("./components/patients/WaitingList"));
const AddPatients = React.lazy(() => import("./components/patients/AddPatients"));
const EditPatients = React.lazy(() => import("./components/patients/EditPatients"));
const EditPatientInfo = React.lazy(() => import("./components/patients/EditPatientInfo"));
const PatientsProfile = React.lazy(() => import("./components/patients/PatientsProfile"));
const ProfilePatient = React.lazy(() => import("./components/patients/Profile"));
const ClinicalNotes = React.lazy(() => import("./components/patients/ClinicalNotes"));
const PatientFile = React.lazy(() => import("./components/patients/PatientFile"));
const BookPatientAppointment = React.lazy(() => import("./components/patients/PatientAppointment"));
const PatientsUpload = React.lazy(() => import("./components/patients/PatientsUpload"));
const TriagePatients = React.lazy(() => import("./components/patients/Triage_Patients"));
const TriagePatientsWaiting = React.lazy(() => import("./components/patients/Triage_Patients_Waiting"));
const EditTriage = React.lazy(() => import("./components/patients/EditTriage"));
const EditMedical = React.lazy(() => import("./components/patients/EditMedical"));

// Users
const UsersList = React.lazy(() => import("./components/users/UserList"));
const AddUsers = React.lazy(() => import("./components/users/AddUsers"));
const EditUsers = React.lazy(() => import("./components/users/EditUser"));
const EditUserPassword = React.lazy(() => import("./components/users/EditPassword"));
const UsersProfile = React.lazy(() => import("./components/users/UserProfile"));

const AppoinmentList = React.lazy(() => import("./components/appoinments/AppoinmentList"));
const AddAppoinments = React.lazy(() => import("./components/appoinments/AddAppoinments"));
const PatientAppointment = React.lazy(() => import("./components/appoinments/PatientAppointment"));
const EditAppoinments = React.lazy(() => import("./components/appoinments/EditAppoinments"));
//DoctorSchedule
const ScheduleList = React.lazy(() => import("./components/doctorschedule/ScheduleList"));

const AddSchedule = React.lazy(() => import("./components/doctorschedule/AddSchedule"));
const EditSchedule = React.lazy(() => import("./components/doctorschedule/EditSchedule"));
//Clinic
const ClinicList = React.lazy(() => import("./components/clinic/ListClinic"));
const AddClinic = React.lazy(() => import("./components/clinic/AddClinic"));
const EditClinic = React.lazy(() => import("./components/clinic/EditClinic"));

//Departments
const DepartmentList = React.lazy(() => import("./components/department/DepartmentList"));
const AddDepartment = React.lazy(() => import("./components/department/AddDepartment"));
const EditDepartment = React.lazy(() => import("./components/department/EditDepartment"));
const ClinicProfile = React.lazy(() => import("./components/department/ClinicProfile"));
const ClinicSettings = React.lazy(() => import("./components/department/ClinicSettings"));
const ClinicService = React.lazy(() => import("./components/department/ClinicService"));
const ClinicPrescription = React.lazy(() => import("./components/department/ClinicPrescription"));
const ClinicProcedures = React.lazy(() => import("./components/department/ClinicProcedures"));
const ClinicLabs = React.lazy(() => import("./components/department/ClinicLabs"));
const EditService = React.lazy(() => import("./components/department/EditService"));
const EditSpeciality = React.lazy(() => import("./components/department/EditSpeciality"));
const EditPrescription = React.lazy(() => import("./components/department/EditPrescription"));
const EditProcedure = React.lazy(() => import("./components/department/EditProcedure"));
const EditLabs = React.lazy(() => import("./components/department/EditLabs"));


const StaffList = React.lazy(() => import("./components/staff/StafList"));
const AddStaff = React.lazy(() => import("./components/staff/Add-Staff"));
const StaffProfile = React.lazy(() => import("./components/staff/StaffProfile"));
const ProvidentFund = React.lazy(() => import("./components/accounts/ProvidentFund"));
const ForgotPassword = React.lazy(() => import("./components/pages/login/ForgotPassword"));
const Signup = React.lazy(() => import("./components/pages/login/Signup"));
const Invoice = React.lazy(() => import("./components/accounts/Invoice"));
const Create_Invoice = React.lazy(() => import("./components/accounts/Create_Invoice"));
const Payments = React.lazy(() => import("./components/accounts/Payments"));
const PaymentsDay = React.lazy(() => import("./components/accounts/Payments_Day"));
const PaymentsWeek = React.lazy(() => import("./components/accounts/Payments_Week"));
const PaymentsMonth = React.lazy(() => import("./components/accounts/Payments_Month"));
const AppointmentPayments = React.lazy(() => import("./components/accounts/AppointmentList"));
const Add_Payment = React.lazy(() => import("./components/accounts/Add_Payment"));
const Expenses = React.lazy(() => import("./components/accounts/Expenses"));
const Add_Expense = React.lazy(() => import("./components/accounts/Add_Expense"));
const Taxes = React.lazy(() => import("./components/accounts/Taxes"));
const Add_Tax = React.lazy(() => import("./components/accounts/Add_Tax"));
const EmployeeSalary = React.lazy(() => import("./components/Payroll/EmployeeSalary/EmployeeSalary"));
const GrokAI = React.lazy(() => import("./components/GrokAI/GrokAIC"));

const Inbox = React.lazy(() => import("./components/email/Inbox"));
const AddLeave = React.lazy(() => import("./components/staff/AddLeave"));
const Attendence = React.lazy(() => import("./components/staff/Attendence"));
const Leave = React.lazy(() => import("./components/staff/Leave"));
const ComposeMail = React.lazy(() => import("./components/email/ComposeMail"));
const MailView = React.lazy(() => import("./components/email/MailView"));
const UserActivity = React.lazy(() => import("./components/activity/UserActivity"));
const AddEmployeeSalary = React.lazy(() => import("./components/Payroll/EmployeeSalary/AddEmployeeSalary"));
const Chat = React.lazy(() => import("./components/Chat/Chat"));
const VoiceCall = React.lazy(() => import("./components/Call/VoiceCall"));
const VideoCall = React.lazy(() => import("./components/Call/VideoCall"));
const EditStaff = React.lazy(() => import("./components/staff/EditStaff"));
const EditLeave = React.lazy(() => import("./components/staff/EditLeave"));
const Holiday = React.lazy(() => import("./components/staff/Holiday"));
const Add_ProviderFund = React.lazy(() => import("./components/accounts/Add_ProviderFund"));
const ExpensesReport = React.lazy(() => import("./components/ExpenseReport/Expenses/ExpensesReport"));
const AddExpenses = React.lazy(() => import("./components/ExpenseReport/Expenses/AddExpenses"));
const Invoice_Report = React.lazy(() => import("./components/ExpenseReport/Invoice-report/Invoice_Report"));
const OverDue = React.lazy(() => import("./components/Invoice/Invoice-List/Overdue-Invoice/OverDue"));
const InvoiceList = React.lazy(() => import("./components/Invoice/Invoice-List/InvoiceList"));
const Paid_Invoice = React.lazy(() => import("./components/Invoice/Invoice-List/Paid-Invoice/Paid_Invoice"));
const Draft_Invoice = React.lazy(() => import("./components/Invoice/Invoice-List/Draft_Invoice/Draft_Invoice"));
const Recurring_Invoice = React.lazy(() => import("./components/Invoice/Invoice-List/Recurring_Invoice/Recurring_Invoice"));
const Cancelled_Invoice = React.lazy(() => import("./components/Invoice/Invoice-List/Cancelled_Invoice/Cancelled_Invoice"));
const Invoice_Grid = React.lazy(() => import("./components/Invoice/Invoices_Grid/Invoice_Grid"));
const Add_Invoices = React.lazy(() => import("./components/Invoice/Add_Invoices/Add_Invoices"));
const Edit_Invoices = React.lazy(() => import("./components/Invoice/Edit_Invoices/Edit_Invoices"));
const Invoice_Details = React.lazy(() => import("./components/Invoice/Invoice_Details/Invoice_Details"));
const Invoice_GeneralSettings = React.lazy(() => import("./components/Invoice/Invoice_Settings/General_Settings/Invoice_GeneralSettings"));
const Tax_Settings = React.lazy(() => import("./components/Invoice/Invoice_Settings/Tax_Settings/Tax_Settings"));
const Bank_Settings = React.lazy(() => import("./components/Invoice/Invoice_Settings/Bank_Settings/Bank_Settings"));
const IncomingCall = React.lazy(() => import("./components/Chat/IncomingCall"));
const BasicInput = React.lazy(() => import("./components/Forms/BasicInput"));
const InputGroups = React.lazy(() => import("./components/Forms/InputGroups"));
const HorizontalForm = React.lazy(() => import("./components/Forms/HorizontalForm"));
const VerticalForm = React.lazy(() => import("./components/Forms/VerticalForm"));
const BasicTable = React.lazy(() => import("./components/Tables/BasicTable"));
const DataTable = React.lazy(() => import("./components/Tables/DataTable"));
const UiKit = React.lazy(() => import("./components/Ui_Elements/UiKit"));
const Typography = React.lazy(() => import("./components/Ui_Elements/Typography"));
const Tab = React.lazy(() => import("./components/Ui_Elements/Tab"));
const Edit_Assets = React.lazy(() => import("./components/assests/Edit_Assets"));
const Register = React.lazy(() => import("./components/pages/login/Register"));
const LockScreen = React.lazy(() => import("./components/pages/login/LockScreen"));
const ChangePassword = React.lazy(() => import("./components/pages/login/ChangePassword"));
const Error = React.lazy(() => import("./components/pages/login/Error"));
const ServerError = React.lazy(() => import("./components/pages/login/ServerError"));
const EditEmployeeSalery = React.lazy(() => import("./components/Payroll/EmployeeSalary/EditEmployeeSalery"));
const Calender = React.lazy(() => import("./components/calender/Calender"));
const Profile = React.lazy(() => import("./components/pages/login/Profile"));
const EditProfile = React.lazy(() => import("./components/pages/login/EditProfile"));
const BlankPage = React.lazy(() => import("./components/pages/login/BlankPage"));
const Doctor_Dashboard = React.lazy(() => import("./components/Dashboard/Doctor_Dashboard/Doctor_Dashboard"));
const Admin_Dashboard = React.lazy(() => import("./components/Dashboard/Admin_Dashboard/Admin_Dashboard"));
const Patient_Dashboard = React.lazy(() => import("./components/Dashboard/Patient_Dashboard/Patient_Dashboard"));
const Doctor_Settings = React.lazy(() => import("./components/Dashboard/Doctor_Dashboard/Doctor_Settings"));
const Patient_Settings = React.lazy(() => import("./components/patients/Patient_Settings"));
const Staff_Settings = React.lazy(() => import("./components/staff/Staff-Settings"));
const Edit_Provident = React.lazy(() => import("./components/accounts/Edit_Provident"));
const Edit_Taxes = React.lazy(() => import("./components/accounts/Edit_Taxes"));
const Edit_Expenses = React.lazy(() => import("./components/ExpenseReport/Expenses/Edit_Expenses"));
const Edit_Payment = React.lazy(() => import("./components/accounts/Edit_Payment"));
const Payslip = React.lazy(() => import("./components/Payroll/Payslip"));
const Setting = React.lazy(() => import("./components/settings/Setting"));
const GalleryImage = React.lazy(() => import("./components/pages/Gallery/Gallery"));
import Logout from "./util/logout";
import { AuthContext } from "./store/auth-context";

//Accounts
const Approuter = () => {
  const loader = useLoader();
  const token = getAuthToken();

  // console.log("The App side token:" + token);
  // console.log("The App side token for loader:" + loader);

  // Check if loader is undefined
  if (!loader) {
    // Handle the case when useLoader is undefined
    return <div>Loading...</div>; // You can replace this with an appropriate loading component
  }

  const { isLoading, authToken } = loader;

  useEffect(() => {
    // Do something with authToken if needed
  }, [authToken]);

  // if (isLoading) {
  //   return <GlobalLoadingFallback />;
  // }

  // eslint-disable-next-line no-unused-vars
  // const config = "/react/template"
  return (
    <>
      <BrowserRouter basename="/">
        <Suspense fallback={<GlobalLoadingFallback/>}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/register" element={<Register />} />
            <Route path="/lockscreen" element={<LockScreen />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/error" element={<Error />} />
            <Route path="/server-error" element={<ServerError />} />
            <Route path="/blankpage" element={<BlankPage />} />
            <Route path="/gallery" element={<GalleryImage />} />
            {/* Blog */}
            <Route path="/blog" element={<Blogdetails />} />
            <Route path="/addblog" element={<Addblog />} />
            <Route path="/editblog" element={<Editblog />} />
            <Route path="/blogview" element={<BlogView />} />
            {/* Settings */}
            <Route path="/settings" element={<Setting />} />
            <Route path="/localization" element={<Localization />} />
            <Route path="/paymentsetting" element={<Paymentsetting />} />
            <Route path="/settingsemail" element={<Settingsemail />} />
            <Route
              path="/settingssocialmedia"
              element={<Settingssocialmedia />}
            />
            <Route
              path="/settingssociallink"
              element={<Settingssociallinks />}
            />
            <Route path="/settingsseo" element={<Settingsseo />} />
            <Route path="/settingsthem" element={<SettingsThem />} />
            <Route
              path="/settingschangepassword"
              element={<SettingsChangePassword />}
            />
            <Route path="/settingsothers" element={<SettingsOthers />} />
            {/* Assests */}
            <Route path="/assests" element={<Assests />} />
            <Route path="/addasset" element={<AddAsset />} />
            <Route path="/edit-assets" element={<Edit_Assets />} />
            {/* Doctor  */}
            <Route path="/doctorlist" element={<DoctorList />} />
            <Route path="/add-doctor" element={<AddDoctor />} />
            <Route path="/editdoctor/:consultantId" element={<EditDoctor />} />
            <Route path="/doctorprofile" element={<DoctorProfile />} />
            <Route path="/doctor-setting" element={<Doctor_Settings />} />
            {/* Receptionist  */}
            <Route path="/receptionistlist" element={<ReceptionistList />} />
            <Route path="/add-receptionist" element={<AddReceptionist />} />
            <Route path="/editreceptionist/:receptionistId" element={<EditReceptionist />} />
            {/* Nurse  */}
            <Route path="/nurselist" element={<NurseList />} />
            <Route path="/add-nurse" element={<AddNurse />} />
            <Route path="/editnurse/:nurseId" element={<EditNurse />} />

            {/* GrokAI */}
            <Route path="/grokAI" element={<GrokAI />} />
            {/* Patients */}
            <Route path="/patientslist" element={<PatientsList />} />
            <Route path="/waitinglist" element={<WaitingList />} />
            <Route path="/addpatients" element={<AddPatients />} />
            <Route path="/profilepatient/:patientCode" element={<ProfilePatient />} />
            <Route path="/patientshr/:patientId" element={<EditPatients />} />
            <Route path="/patientbilling/:patientCode" element={<PatientBilling />} />
            <Route path="/patientsdetails/:patientCode" element={<EditPatientInfo />} />
            <Route path="/patientstriage/:patientId" element={<TriagePatients />} />
            <Route path="/patientstriagewaiting/:patientId" element={<TriagePatientsWaiting />} />
            <Route path="/patientsprofile/:patientCode" element={<PatientsProfile />} />
            <Route path="/clinicalnotes/:patientCode/:waitingId" element={<ClinicalNotes />} />
            <Route path="/patientfile/:patientCode/:waitingId" element={<PatientFile />} />
            <Route path="/bookpatientappointment/:patientCode/:waitingId" element={<BookPatientAppointment />} />
            <Route path="/patientsupload/:patientId" element={<PatientsUpload />} />
            <Route path="/edittriagerecords/:triageId" element={<EditTriage />} />
            <Route path="/checkclinicalnotes/:medicalId/:patientCode" element={<EditMedical />} />
            <Route path="/patient-settings" element={<Patient_Settings />} />
            {/* Users */}
            <Route path="/userslist" element={<UsersList />} />
            <Route path="/addusers" element={<AddUsers />} />
            <Route path="/editusers/:userId" element={<EditUsers />} />
            <Route path="/edituserpassword/:userId" element={<EditUserPassword />} />
            <Route path="/usersprofile/:userId" element={<UsersProfile />} />
            {/* Appoinments */}
            <Route path="/appoinmentlist" element={<AppoinmentList />} />
            <Route path="/addappointments/:patientId/:clinicId" element={<AddAppoinments />} />
            <Route path="/editappoinments/:appointmentId" element={<EditAppoinments />} />
            <Route path="/patientappoinment" element={<PatientAppointment />} />
            
            {/* DoctorSchedule */}
            <Route path="/schedulelist" element={<ScheduleList />} />
            <Route path="/addschedule" element={<AddSchedule />} />
            <Route path="/editschedule" element={<EditSchedule />} />
            {/* Department or Clinic*/}
            <Route path="/clinicprofile/:clinicId" element={<ClinicProfile />} />
            <Route path="/clinicspeciality/:clinicId" element={<ClinicSettings />} />
            <Route path="/clinicservice/:clinicId" element={<ClinicService />} />
            <Route path="/clinicprescriptions/:clinicId" element={<ClinicPrescription />} />
            <Route path="/clinicprocedures/:clinicId" element={<ClinicProcedures />} />
            <Route path="/cliniclabs/:clinicId" element={<ClinicLabs />} />
            <Route path="/cliniclist" element={<DepartmentList />} />
            <Route path="/add-clinic" element={<AddDepartment />} />
            <Route
              path="/editclinic/:clinicId"
              element={<EditDepartment />}
            />
            <Route path="/editclinicservice/:clinicId/:serviceId" element={<EditService />} />
            <Route path="/editclinicspeciality/:clinicId/:specialityId" element={<EditSpeciality />} />
            <Route path="/editclinicprescription/:clinicId/:prescriptionId" element={<EditPrescription />} />
            <Route path="/editclinicprocedure/:clinicId/:procedureId" element={<EditProcedure />} />
            <Route path="/editcliniclabs/:clinicId/:labId" element={<EditLabs />} />
            {/* Clinic */}
            {/* <Route path="/cliniclist" element={<ClinicList />} />
            <Route path="/add-clinic" element={<AddClinic />} />
            <Route path="/editclinic" element={<EditClinic />} /> */}
            {/* Staff */}
            <Route path="/stafflist" element={<StaffList />} />
            <Route path="/addstaff" element={<AddStaff />} />
            <Route path="/editstaff" element={<EditStaff />} />
            <Route path="/staffprofile" element={<StaffProfile />} />
            <Route path="/leave" element={<Leave />} />
            <Route path="/add-leave" element={<AddLeave />} />
            <Route path="/editleave" element={<EditLeave />} />
            <Route path="/attendence" element={<Attendence />} />
            <Route path="/holiday" element={<Holiday />} />
            <Route path="/staff-settings" element={<Staff_Settings />} />
            {/* Accounts */}
            <Route path="/providentfund" element={<ProvidentFund />} />
            <Route path="/add-providerfund" element={<Add_ProviderFund />} />
            <Route path="/invoicelist" element={<Invoice />} />
            <Route path="/createinvoice" element={<Create_Invoice />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/paymentsday" element={<PaymentsDay />} />
            <Route path="/paymentsweek" element={<PaymentsWeek />} />
            <Route path="/paymentsmonth" element={<PaymentsMonth />} />
            <Route path="/appointmentpayments" element={<AppointmentPayments />} />
            <Route path="/updatepayment/:paymentId" element={<Add_Payment />} />
            <Route path="/expenses" element={<Expenses />} />
            <Route path="/addexpense" element={<Add_Expense />} />
            <Route path="/taxes" element={<Taxes />} />
            <Route path="/edit-taxes" element={<Edit_Taxes />} />
            <Route path="/addtax" element={<Add_Tax />} />
            <Route path="/edit-provident" element={<Edit_Provident />} />
            <Route path="/edit-payment" element={<Edit_Payment />} />
            {/* /* Payroll */}
            <Route path="/employeesalary" element={<EmployeeSalary />} />
            <Route path="/addsalary" element={<AddEmployeeSalary />} />
            <Route path="/editsalary" element={<EditEmployeeSalery />} />
            <Route path="/payslip" element={<Payslip />} />
            {/* Email */}
            <Route path="/inbox" element={<Inbox />} />
            <Route path="/compose-mail" element={<ComposeMail />} />
            <Route path="/mail-view" element={<MailView />} />
            {/* Activity */}
            <Route path="/user-activity" element={<UserActivity />} />
            {/* ExpenseReport */}
            <Route path="/expense-Report" element={<ExpensesReport />} />
            <Route path="/add-expense" element={<AddExpenses />} />
            <Route path="/invoice-report" element={<Invoice_Report />} />
            <Route path="/edit-expenses" element={<Edit_Expenses />} />
            {/* Chat */}
            <Route path="/chat" element={<Chat />} />
            {/* Call */}
            <Route path="/voice-call" element={<VoiceCall />} />
            <Route path="/video-call" element={<VideoCall />} />
            <Route path="/incoming-call" element={<IncomingCall />} />
            {/* Invoice */}
            <Route path="/invoice-list" element={<InvoiceList />} />
            <Route path="/paid-invoice" element={<Paid_Invoice />} />
            <Route path="/overdue-invoice" element={<OverDue />} />
            <Route path="/draft-invoice" element={<Draft_Invoice />} />
            <Route path="/recurring-invoice" element={<Recurring_Invoice />} />
            <Route path="/cancelled-invoice" element={<Cancelled_Invoice />} />
            <Route path="/invoice-grid" element={<Invoice_Grid />} />
            <Route path="/add-invoice" element={<Add_Invoices />} />
            <Route path="/edit-invoice" element={<Edit_Invoices />} />
            <Route path="/invoice-details" element={<Invoice_Details />} />
            <Route
              path="/invoice-settings"
              element={<Invoice_GeneralSettings />}
            />
            <Route path="/tax-settings" element={<Tax_Settings />} />
            <Route path="/bank-settings" element={<Bank_Settings />} />
            {/* ui-elements */}
            <Route path="/ui-kit" element={<UiKit />} />
            <Route path="/typography" element={<Typography />} />
            <Route path="/tab" element={<Tab />} />
            {/* Forms */}
            <Route path="/basic-input" element={<BasicInput />} />
            <Route path="/inputgroup" element={<InputGroups />} />
            <Route path="/horizontal-form" element={<HorizontalForm />} />
            <Route path="/vertical-form" element={<VerticalForm />} />
            {/* Tables */}
            <Route path="/basic-table" element={<BasicTable />} />
            <Route path="/data-table" element={<DataTable />} />
            {/* Calender */}
            <Route path="/calender" element={<Calender />} />
            {/* Dashboard */}
            <Route
              path="/admin-dashboard"
              element={<Admin_Dashboard authToken={token} />}
            />
            <Route path="/doctor-dashboard" element={<Doctor_Dashboard />} />
            <Route path="/clinic-dashboard" element={<Patient_Dashboard />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </Suspense>
        <div className="sidebar-overlay"></div>
      </BrowserRouter>
    </>
  );
};

export default Approuter;
